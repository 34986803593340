/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  width: 100%;
  max-width: 100px;
}

.scale {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  top: 50vh;
  left: 5vw;
  width: 90vw;

  position: absolute;
  z-index: -1;
  font-size: 5rem;
  font-family: sans-serif;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.scale p {
  -webkit-flex: 0 0 5rem;
          flex: 0 0 5rem;
  padding: 1rem;
  text-align: center;
  background-color: white;
}

.dotted-line {
  border-top: 10px dashed black;
  width: calc(100vw - 300px);
  z-index: -2;
}

.static-box {
  background-color: "white";
}

button {
  position: absolute;
  bottom: 0;
  color: white;

  padding: 1rem;
  font-weight: bold;
}

button.quibi {
  right: 0;
  background: linear-gradient(
    135deg,
    rgb(79, 120, 255),
    rgb(0, 0, 161),
    rgb(230, 50, 80)
  );
}

button.piracy {
  background: black;
  right: 10rem;
}

